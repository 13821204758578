import {
  AtlasDialogContentProps,
  AtlasDialogState,
  Dialog,
  useDialogState,
} from "@resource/atlas";
import { ComponentType, useEffect, useState } from "react";

import useModalContext, { ModalName, ModalProps } from "./ModalContext";

export type CommonModalProps = {
  state: AtlasDialogState;
  close: () => void;
};

export type FullModalProps<N extends ModalName> = CommonModalProps &
  ModalProps[N]["input"] & {
    done: (result: ModalProps[N]["result"]) => void;
  };

function asModal<N extends ModalName>(
  name: N,
  Component: ComponentType<FullModalProps<N>>,
  /**
   * Pass true if you are passing Dialog.Content as Component.
   * AtlasDialogContentProps if you are passing Dialog.View as Component.
   */
  isContentOrContentProps?: AtlasDialogContentProps | true
) {
  function ModalifiedComponent() {
    const [props, setProps] = useState<ModalProps[N]["input"]>();
    const { active, isActive, close } = useModalContext();
    const state = useDialogState({
      open: isActive(name),
      setOpen: (open) => (open ? undefined : close()),
    });

    // Doing this to make sure dialog has a state during unmount animation
    useEffect(() => {
      if (isActive(name)) {
        // TODO: Fix Typing here
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setProps(active?.props as any);
      }
    }, [isActive, active]);

    // TODO: Fix Typing here
    const fullProps = {
      ...props,
      state,
      close,
      done: (result: ModalProps[N]["result"]) => {
        if (active) {
          close({ key: active.key, name: active.name as never, result });
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    return (
      <Dialog.Root state={state}>
        {isContentOrContentProps === true ? (
          <Component {...fullProps} />
        ) : (
          <Dialog.Content {...isContentOrContentProps}>
            <Component {...fullProps} />
          </Dialog.Content>
        )}
      </Dialog.Root>
    );
  }

  return ModalifiedComponent;
}

export default asModal;
