import { omit } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { analytics } from "react-hooks/useAnalytics";
import routeForPathname from "utils/routing";

import { Events, Projects } from "./types";

type EventProps = { project?: Projects | Projects[] } & Record<string, unknown>;
type DefaultProps = { component: string } & EventProps;

export const useLogEvent = <E extends Record<string, unknown>>(
  defaultProps: DefaultProps
) => {
  const router = useRouter();

  const logEventWrapper = useCallback(
    (
      event: Events,
      eventProps?: E & EventProps,
      options?: Record<string, unknown>
    ) => {
      const page = routeForPathname(router.pathname);
      const props = {
        ...defaultProps,
        ...eventProps,
        page: page?.name,
        pathname: router.pathname,
      };

      // making sure that we standardize to array when logging
      const projectsStr =
        (Array.isArray(props.project)
          ? props.project?.join(",")
          : props.project) || "";

      return analytics.track(
        event,
        { ...omit(props, "project"), projects: projectsStr },
        options
      );
    },
    [defaultProps, router.pathname]
  );

  return useMemo(() => logEventWrapper, [logEventWrapper]);
};
