/* eslint-disable import/prefer-default-export */
import { useDialogState as useAriakitDialogState } from "ariakit";

import { SHARED_POPOVER_STATE_DEFAULTS } from "../__utils/atlas";
import { createDefaultProps } from "../__utils/react";
import type { AtlasDialogState, AtlasDialogStateProps } from "./types";

const DEFAULT_PROPS = createDefaultProps<AtlasDialogStateProps>()({
  animated: SHARED_POPOVER_STATE_DEFAULTS.animated,
} as const);

/**
 * Creates a dialog state.
 *
 * @example
 * const dialog = useDialogState();
 * return <Dialog.Root state={dialog}>...</Dialog.Root>;
 */
export function useDialogState({
  animated = DEFAULT_PROPS.animated,
  ...props
}: AtlasDialogStateProps = {}) {
  const dialog: AtlasDialogState = useAriakitDialogState({
    animated,
    ...props,
  });
  return dialog;
}
