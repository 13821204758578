import { throttle } from "lodash";
import { RefObject, useCallback, useEffect, useMemo } from "react";
import { useIsomorphicLayoutEffect } from "react-use";
import useStateRef from "react-usestateref";

// utils
// -----

type RefOrElement<T extends Element> = RefObject<T> | T | undefined | null;

function getElement<T extends Element>(
  refOrElement: RefOrElement<T>
): T | undefined {
  return (
    (refOrElement && "current" in refOrElement
      ? refOrElement.current
      : refOrElement) ?? undefined
  );
}

// use resize observer
// -------------------

/** Tracks an element with `ResizeObserver` and calls the provided callback on updates. */
export function useResizeObserver<
  T extends Element,
  F extends (...args: unknown[]) => unknown
>(refOrElement: RefOrElement<T>, callback: F) {
  useEffect(() => {
    const element = getElement(refOrElement);
    if (element && typeof window !== "undefined" && window.ResizeObserver) {
      const resizeObserver = new ResizeObserver((entries) =>
        // wrapped in requestAnimationFrame to avoid this error: ResizeObserver loop limit exceeded
        window.requestAnimationFrame(() => entries.length && callback())
      );
      resizeObserver.observe(element);
      return () => resizeObserver.disconnect();
    }

    return undefined;
  }, [callback, refOrElement]);
}

// use vertical scroll overflow
// ----------------------------

const isEdge =
  typeof window !== "undefined" &&
  /Edge\/\d./i.test(window.navigator.userAgent);

type OverflowingState = {
  isOverflowingTop: boolean;
  isOverflowingBottom: boolean;
};

// greatly inspired by: https://github.com/tomisu/react-element-scroll-hook

/** Determines whether an element is currently overflowing vertically. */
export function useVerticalScrollOverflow<T extends Element>(
  refOrElement?: RefOrElement<T>,
  { throttleWait = 50, onWindow = false } = {}
) {
  const [state, setState, stateRef] = useStateRef<OverflowingState>({
    isOverflowingTop: false,
    isOverflowingBottom: false,
  });

  // re-computes state
  const update = useCallback(() => {
    const element =
      getElement(refOrElement) ??
      (onWindow ? document.documentElement : undefined);

    if (!element) return;

    let maxScroll = element.scrollHeight - element.clientHeight;
    // Edge has a bug where scrollHeight is 1px bigger than clientHeight when there's no scroll.
    if (isEdge && maxScroll === 1 && element.scrollTop === 0) maxScroll = 0;

    const scrollPercentage =
      maxScroll !== 0 ? element.scrollTop / maxScroll : undefined;

    const isOverflowingTop =
      scrollPercentage !== undefined && scrollPercentage > 0;
    const isOverflowingBottom =
      scrollPercentage !== undefined && scrollPercentage < 1;

    // prevent unnecessary re-renders
    const { current: prevState } = stateRef;
    if (
      prevState.isOverflowingTop !== isOverflowingTop ||
      prevState.isOverflowingBottom !== isOverflowingBottom
    )
      setState({ isOverflowingTop, isOverflowingBottom });
  }, [onWindow, refOrElement, setState, stateRef]);

  // throttled update to prevent staggering
  const throttledUpdate = useMemo(
    () => throttle(update, throttleWait),
    [throttleWait, update]
  );

  // re-compute state on element resize
  useResizeObserver(
    onWindow && typeof window !== "undefined"
      ? document.documentElement
      : refOrElement,
    throttledUpdate
  );

  // register listeners
  useIsomorphicLayoutEffect(() => {
    const element =
      getElement(refOrElement) ??
      (onWindow ? document.documentElement : undefined);

    if (!element) return undefined;

    // update on scroll
    const target = onWindow ? window : element;
    target.addEventListener("scroll", throttledUpdate);

    // update on window resize as a fallback if ResizeObserver is not available
    if (onWindow || !window.ResizeObserver)
      window.addEventListener("resize", throttledUpdate);

    return () => {
      // clean up listeners
      target.removeEventListener("scroll", throttledUpdate);
      if (onWindow || !window.ResizeObserver)
        window.removeEventListener("resize", throttledUpdate);
    };
  }, [refOrElement, throttledUpdate]);

  return state;
}

// use window scroll overflow
// --------------------------

/** Determines whether the document is currently overflowing vertically. */
export function useWindowScrollOverflow() {
  return useVerticalScrollOverflow(undefined, { onWindow: true });
}
