import config from "config";
import { useCallback, useEffect, useRef, useState } from "react";

const useExtension = () => {
  const [installed, setInstalled] = useState(false);
  const installationTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const clearInstallationTimeout = useCallback(() => {
    if (installationTimeoutRef.current) {
      clearInterval(installationTimeoutRef.current);
    }
  }, []);

  useEffect(() => clearInstallationTimeout, [clearInstallationTimeout]);

  const send = useCallback(
    (data: Record<string, unknown>) =>
      new Promise<void>((resolve, reject) => {
        try {
          chrome.runtime.sendMessage(config.EXTENSION_ID, data, (response) => {
            if (chrome.runtime.lastError) {
              return reject(chrome.runtime.lastError);
            }
            return resolve(response);
          });
        } catch (e) {
          reject(e);
        }
      }),
    []
  );

  const ping = useCallback(() => send({ type: "ping" }), [send]);

  const isInstalled = useCallback(async () => {
    try {
      await ping();
      setInstalled(true);
      return true;
    } catch (error) {
      return false;
    }
  }, [ping]);

  useEffect(() => {
    isInstalled();
  }, [isInstalled]);

  const waitForInstallation = useCallback(
    () =>
      new Promise<boolean>((resolve) => {
        clearInstallationTimeout();
        installationTimeoutRef.current = setInterval(async () => {
          const hasInstalled = await isInstalled();
          if (hasInstalled) {
            clearInstallationTimeout();
            setInstalled(true);
            resolve(true);
          }
        }, 1000);
      }),
    [isInstalled, clearInstallationTimeout]
  );

  return { installed, send, ping, isInstalled, waitForInstallation };
};

export default useExtension;
