/* eslint-disable import/prefer-default-export */
import clsx from "clsx";
import { ReactNode, useState } from "react";
import { useResizeDetector } from "react-resize-detector";

import { createComponentUtils } from "../__utils/atlas";
import { createDefaultProps, useForkRef } from "../__utils/react";
import { ButtonGroup } from "../button";
import { useVerticalScrollOverflow } from "../utils";
import type {
  AtlasDialogLayoutComponent,
  AtlasDialogLayoutHeader,
  AtlasDialogLayoutProps,
} from "./types";

// config
// ------

const COMPONENT_NAME = "DialogLayout";
const DEFAULT_PROPS = createDefaultProps<AtlasDialogLayoutProps>()({
  hasPadding: true,
} as const);

const DEFAULT_SEPARATOR_VISIBILITY = "overflow";

const { ROOT, el, createComponent } = createComponentUtils(COMPONENT_NAME);

// dialog layout
// -------------

function renderHeaderTitle(
  { title, renderTitle }: AtlasDialogLayoutHeader = {},
  isHidden = false
): ReactNode {
  const titleProps = {
    className: clsx(el`title`, { isHidden }),
    children: title,
  };

  return renderTitle ? renderTitle(titleProps) : <p {...titleProps} />;
}

/** A component that renders the basic layout and structure of a dialog. It has no built-in
 * dialog behavior (e.g. showing/hiding, positioning, etc) or accessibility semantics.
 *
 * @example
 * <DialogLayout
 *   header={header}
 *   footer={footer}
 * >
 *   {body}
 * </DialogLayout>
 */
export const DialogLayout = createComponent<AtlasDialogLayoutComponent>(
  ({
    hasPadding = DEFAULT_PROPS.hasPadding,
    header,
    footer,
    children,
    bodyProps,
    ...props
  }) => {
    // keep track of vertical overflow to show separators on the header or footer
    const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(
      null
    );
    const { isOverflowingTop, isOverflowingBottom } =
      useVerticalScrollOverflow(scrollElement);

    // measure the header right actions area and apply the same size to the left actions area
    // to keep the title centered without altering the positioning of the header elements, so
    // we can have proper overflow and text-wrapping behavior
    const { width: headerRightActionsWidth, ref: headerRightActionsRef } =
      useResizeDetector({
        handleHeight: false,
        // see https://github.com/maslianok/react-resize-detector/issues/45#issuecomment-829928247
        refreshMode: "debounce",
        refreshRate: 0,
      });

    // compute separator visibility
    const headerSeparatorAlwaysVisible =
      (header?.separatorVisible ?? DEFAULT_SEPARATOR_VISIBILITY) === "always";
    const footerSeparatorAlwaysVisible =
      (footer?.separatorVisible ?? DEFAULT_SEPARATOR_VISIBILITY) === "always";

    const headerSeparatorVisible =
      headerSeparatorAlwaysVisible || isOverflowingTop;
    const footerSeparatorVisible =
      footerSeparatorAlwaysVisible || isOverflowingBottom;

    return (
      <div
        {...props}
        className={clsx(
          ROOT,
          {
            headerSeparatorVisible,
            footerSeparatorVisible,
            hasFooter: Boolean(footer),
            hasPadding,
          },
          props.className
        )}
      >
        <header className={el`header`}>
          <div className={el`header-row`}>
            <ButtonGroup
              style={{ width: headerRightActionsWidth }}
              isGhost
              size="small"
              negativeMargin="left"
            >
              {header?.leftActions}
            </ButtonGroup>
            {/* prevent title from jumping around while the header layout is recalculated */}
            {renderHeaderTitle(header, headerRightActionsWidth == null)}
            <ButtonGroup
              ref={headerRightActionsRef}
              className={el`header-right-actions`}
              isGhost
              size="small"
              negativeMargin="right"
            >
              {header?.rightActions}
            </ButtonGroup>
          </div>
          {header?.slot && <div className={el`header-slot`}>{header.slot}</div>}
        </header>
        <div
          {...bodyProps}
          ref={useForkRef(setScrollElement, bodyProps?.ref)}
          className={clsx(el`body`, bodyProps?.className)}
        >
          {children}
        </div>
        {footer && (
          <footer>
            <div className={el`footer`}>
              <ButtonGroup
                className={el`footer-left-actions`}
                isGhost
                negativeMargin="left"
              >
                {footer.leftActions}
              </ButtonGroup>
              <ButtonGroup negativeMargin="right">
                {footer.rightActions}
              </ButtonGroup>
            </div>
            {footer.banner && (
              <div className={el`footer-banner`}>{footer.banner}</div>
            )}
          </footer>
        )}
      </div>
    );
  }
);
