import { Button, Dialog, Icon, Link } from "@resource/atlas";
import { atlasLinkExternal, atlasLogoChromeColor } from "@resource/atlas/icons";
import { useLogEvent } from "analytics";
import Image from "next/image";
import greenhouseImage from "public/images/chrome-install-greenhouse.png";
import { useCallback, useState } from "react";
import useExtension from "react-hooks/useExtension";
import { CHROME_EXTENSION_INSTALL_PAGE } from "utils/constants/href";
import useIntercom from "utils/useIntercom";

import asModal from "./Modal";
import useModalContext, { ModalName } from "./ModalContext";

export interface ExtensionInstallProps {
  onInstalled?: () => unknown;
}

function ExtensionInstallModal({ onInstalled }: ExtensionInstallProps) {
  const { callIntercom } = useIntercom();
  const { close } = useModalContext();
  const [loading, setLoading] = useState(false);
  const { waitForInstallation } = useExtension();
  const logEvent = useLogEvent({
    component: "ExtensionInstallModal",
    project: "Check and prompt Extension install",
  });

  const onClick = useCallback(async () => {
    logEvent("Install Button Clicked");
    setLoading(true);
    window.open(CHROME_EXTENSION_INSTALL_PAGE, "_blank");
    await waitForInstallation();
    setLoading(false);
    close();
    onInstalled?.();
  }, [close, onInstalled, waitForInstallation, logEvent]);

  return (
    <Dialog.View className="relative">
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-5">
          <h2 className="text-h2">Get the Guide extension</h2>
          <p className="text-body-md mt-2">
            To create and manage guides you’ll need the Guide browser extension.
            Follow the instructions below to get up and running.
          </p>
          <div className="mt-6 p-6 bg-light-gray-200 rounded-md text-black">
            <div className="flex">
              <Icon className="inline mr-2" content={atlasLogoChromeColor} />
              <h4 className="text-h4">Chrome extension</h4>
            </div>
            <p className="text-body-md mt-2">
              To install our Chrome extension, click the button below to visit
              the Chrome Web Store and select &quot;Add to Chrome&quot;.
            </p>
            <Button
              className="mt-4"
              variant="primary"
              isLoading={loading}
              onClick={onClick}
            >
              <Icon className="inline mr-2" content={atlasLinkExternal} />
              <span>Install Chrome extension</span>
            </Button>
          </div>
          <h4 className="mt-6 text-h4">How do I use the extension?</h4>
          <p className="mt-2 text-body-md">
            To install our Chrome extension, click the button below to visit the
            Chrome Web Store and select &quot;Add to Chrome&quot;.
          </p>
          <p className="mt-4 text-body-md">
            Having trouble?{" "}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              as="button"
              onClick={() => {
                logEvent("Help Button Clicked");
                callIntercom("show");
              }}
            >
              Let us help
            </Link>
          </p>
        </div>
        <div className="p-11 col-span-12 lg:w-[540px] bg-purple-500 lg:absolute top-0 bottom-0 right-0 flex flex-col justify-center items-center">
          <Image src={greenhouseImage} />
          <p className="text-body-lg-heavy center text-white mt-4 text-center">
            Open a candidate profile and starting sending guides!
          </p>
        </div>
      </div>
    </Dialog.View>
  );
}

export default asModal(ModalName.EXTENSION_INSTALL, ExtensionInstallModal, {
  size: "large",
});
