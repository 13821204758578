/* eslint-disable import/prefer-default-export */
import clsx from "clsx";

import { createComponentUtils } from "../__utils/atlas";
import type { AtlasButtonGroupComponent } from "./types";
import {
  ButtonOptionsProvider,
  extractButtonOptions,
} from "./utils/button-options";

// config
// ------

const COMPONENT_NAME = "ButtonGroup";

const { ROOT, createComponent } = createComponentUtils(COMPONENT_NAME);

// button group
// ------------

/**
 * A group of buttons. Options passed to this component will be reflected in the buttons
 * it contains, unless they override it.
 *
 * @example
 * ```jsx
 * <ButtonGroup size="medium" isGhost>
 *   <Button>Cancel</Button>
 *   <Button variant="primary">Confirm</Button>
 * </ButtonGroup>
 * ```
 */
export const ButtonGroup = createComponent<AtlasButtonGroupComponent>(
  ({ children, ...p0 }) => {
    const [buttonOptions, props] = extractButtonOptions(p0);
    return (
      <div {...props} className={clsx(ROOT, props.className)}>
        <ButtonOptionsProvider value={buttonOptions}>
          {children}
        </ButtonOptionsProvider>
      </div>
    );
  },
  { metadata: { id: COMPONENT_NAME } }
);
