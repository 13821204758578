export const HELP_CENTER_PAGE =
  "https://support.guide.co/en/collections/3873054-legacy-guide-help-center";
export const V2_HELP_CENTER_PAGE = "https://support.guide.co/en";
export const GUIDE_ACADEMY_PAGE = "https://academy.guide.co/";
export const PRIVACY_POLICY_PAGE = "https://guide.co/privacy/";
export const TERMS_OF_SERVICE_PAGE = "https://guide.co/terms/";
export const CHROME_EXTENSION_INSTALL_PAGE = `https://chrome.google.com/webstore/detail/guide/${process.env.NEXT_PUBLIC_CHROME_EXTENSION_IDENTIFIER}`;
export const CHROME_EXTENSION_TUTORIAL =
  "https://support.guide.co/en/articles/5568756-the-new-guide-extension-for-greenhouse";
export const SEND_GUIDES_TUTORIAL = "TODO";
export const HIRING_PLANS_TUTORIAL =
  "https://support.guide.co/en/articles/6465031-understanding-hiring-plans";
export const MESSAGE_TEMPLATES_TUTORIAL =
  "https://support.guide.co/en/articles/7062228-using-message-templates";
export const GUIDE_TEMPLATES_TUTORIAL =
  "https://support.guide.co/en/articles/7127058-guide-templates";
